/** @jsxImportSource @emotion/react */
import "styles/index.scss";

import type { FC } from "react";
import React from "react";

import IntroSection from "components/sections/IntroSection";
import ZeroSection from "components/sections/ZeroSection";
import DownloadButton from "components/sectoins/MobileSection1/DownloadButton/DownloadButton";
import useIsMobile from "hooks/useIsMobile";

import Layout from "../../components/Layout/Layout";
import DesktopSection1 from "../../components/sectoins/DesktopSection1/DesktopSection1";
import MobileSection1 from "../../components/sectoins/MobileSection1/MobileSection1";
import Section3 from "../../components/sectoins/Section3/Section3";
import Section4 from "../../components/sectoins/Section4/Section4";
import Section5 from "../../components/sectoins/Section5/Section5";
import Section6 from "../../components/sectoins/Section6/Section6";

import "swiper/swiper.scss";

const IndexPage: FC = () => {
  const isMobile = useIsMobile();

  return (
    <Layout
      stickyBottom={
        isMobile && (
          <div css={{ padding: "1rem" }}>
            <DownloadButton />
          </div>
        )
      }
    >
      {isMobile ? <MobileSection1 /> : <DesktopSection1 />}
      <IntroSection />
      <ZeroSection />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Layout>
  );
};

export default IndexPage;
