import React from "react";

import { useInView } from "react-intersection-observer";

import Section4Desktop from "./Section4Desktop/Section4Desktop";
import Section4Mobile from "./Section4Mobile/Section4Mobile";

import styles from "./Section4.module.scss";

const Section4: React.FC = () => {
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <section className={styles.section} ref={sectionRef}>
      <Section4Mobile sectionInView={sectionInView} />
      <Section4Desktop sectionInView={sectionInView} />
    </section>
  );
};

export default Section4;
