import type { FC } from "react";
import React, { useState } from "react";

import { colors } from "@PRNDcompany/heydealer-ui";

import QnAItem from "../QnAItem/QnAItem";

import styles from "./QnA.module.scss";

const QnA: FC = () => {
  const [openCollapse, setOpenCollapse] = useState<number | null>(null);

  const faqs = [
    {
      title: "헤이딜러는 무료인가요?",
      content: [
        "네, 그렇습니다. \n헤이딜러에서 제공하는 모든 서비스는 무료입니다. \n시세 조회부터 차량 판매까지 마음 편히 이용해주세요.",
      ],
    },
    {
      title: "self 경매는 무엇인가요?",
      content: [
        "직접 차량을 등록하여 전국 9000여명의 딜러들에게 판매하는 경매 방식입니다. \n고객님이 차량 정보를 등록한 후에 승인이 완료되면, 경매가 시작됩니다.",
        "[self 경매 진행과정]\n1. 차량 정보와 사진을 등록해주세요.\n2. 헤이딜러 승인 후, 경매(48시간)가 시작됩니다. \n3. 마음에 든 견적을 눌러 [ 판매요청 ]해주세요. \n4. 딜러가 방문하여 무료 출장 검차를 하고, 거래가 이뤄집니다.",
        <span key="" style={{ color: colors.brand_primary }}>
          *헤이딜러는 모든 감가를 심사하며, 부당 감가가 발생하면 감가액을 돌려드리고 있습니다.
        </span>,
      ],
    },
    {
      title: "self 경매는 정말 부당 감가를 환급해주나요?",
      content: [
        "네, 그렇습니다.\n헤이딜러는 모든 감가를 심사하고 있습니다. \n헤이딜러 감가심사센터에서 부당 감가로 판단되면 딜러에게 환급을 요청합니다. \n만약 딜러가 환급을 거부할 경우, 딜러 계정은 정지하고, 헤이딜러 본사에서 감가액을 환급합니다.",
        <span key="">
          최종 거래결과는&nbsp;
          <span
            className={styles.link}
            onClick={() =>
              window.open("https://page.heydealer.com/reduction", "_blank", `width=600, height=900, scrollbars=yes`)
            }
          >
            감가심사센터
          </span>
          에서 꼼꼼히 검토하고 있으니 안심하세요.
        </span>,
      ],
    },
    {
      title: "self 경매에서 감가 사유는 어떤 것이 있나요?",
      content: [
        <span key="">
          차량 상태에 대해 정확히 알려주셨다면 감가되지 않습니다. <br />
          미리 올리지 못한 감가사유가 있어도 큰 걱정하지 않으셔도 됩니다. <br />
          <span
            className={styles.link}
            onClick={() => {
              window.open(
                "http://page.heydealer.com/reduction-standard",
                "_blank",
                `width=600, height=900, scrollbars=yes`
              );
            }}
          >
            헤이딜러 감가기준범위
          </span>
          &nbsp;내에서만 가격이 조율됩니다.
        </span>,
      ],
    },
    {
      title: "zero 경매는 무엇인가요?",
      content: [
        "최고가에서 감가 없이, 딜러 대면 없이 차량을 판매하는 경매 방식입니다. \n헤이딜러에서 선발한 전문 선별된 평가사가 정확하게 차량 상태를 진단한 후에 경매가 시작됩니다.",
        "[zero 경매 진행과정]\n1. 평가사 진단을 예약해주세요.\n2. 요청하신 장소에서 출장 진단을 하고, 평균 20분 내에 경매가 시작됩니다.\n3. 경매(48시간) 완료 후, 최대 3일 내로 최고가 판매 여부를 결정해주세요. \n4. 탁송기사 방문 후, 서류가 확인되면 차량 대금이 100% 송금됩니다.\n5. 48시간 내 이전 완료된 등록증을 전달드립니다. ",
        <span key="" style={{ color: colors.brand_primary }}>
          *제로 경매에서는 감가가 발생하지 않습니다.
        </span>,
      ],
    },
    {
      title: "zero 경매는 정말 감가가 없나요?",
      content: ["네, 그렇습니다. \nzero 경매에서는 감가 없이 최고가 그대로 차량을 판매할 수 있습니다. "],
    },
    {
      title: "견적만 받아봐도 되나요?",
      content: [
        "네, 물론입니다. \n고객님이 마음에 드는 견적이 있을 경우에만 판매 요청하시면 됩니다. \n헤이딜러는 판매 의무가 없으니 편하게 견적을 받아보세요. ",
      ],
    },
    {
      title: "견적을 받으면 딜러에게 연락처가 공유되나요?",
      content: [
        "아닙니다. \n고객님이 마음에 드는 견적을 선택하여 판매 요청을 하기 전까지 딜러에게 연락처가 공유되지 않습니다. ",
      ],
    },
    {
      title: "예상 시세는 어떻게 계산되나요?",
      content: [
        "예상 시세는 헤이딜러의 경매 데이터로 계산됩니다.\n헤이딜러는 매달 5만 대 이상 차량과 50만 건 이상 딜러 견적이 입찰 되는 국내 최대 내차팔기 서비스입니다.\n이 데이터를 바탕으로 가장 정확한 내차팔기 시세를 안내해 드리고 있습니다.",
        "다만 사고 여부, 차량 상태, 선택 옵션은 고려되지 않은 평균 시세인데요.\n정확한 내 차의 판매 견적을 받고 싶으시다면, 시세조회 후 견적요청을 신청하세요.\n귀찮은 전화 없이 평균 10건 이상 견적이 도착합니다.",
      ],
    },
  ];

  return (
    <>
      <div className={styles.faq}>
        {faqs.map((faq, index) => {
          return (
            <QnAItem
              key={index}
              title={faq.title}
              content={faq.content}
              collapseNumber={index}
              openCollapse={openCollapse}
              setOpenCollapse={setOpenCollapse}
            />
          );
        })}
      </div>
    </>
  );
};

export default QnA;
