/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { Button, DownloadIcon } from "@PRNDcompany/heydealer-ui";
import { event } from "react-ga";

import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";

const DownloadButton: FC = () => {
  const { url } = useApplink(MKT_PROJECT_KEY);

  return (
    <a href={url.toString()} target="_blank" onClick={() => event({ category: "다운로드 버튼", action: "클릭" })}>
      <Button size={48} icon={DownloadIcon} fullWidth customCSS={{ background: "#2957F2" }}>
        앱 다운로드
      </Button>
    </a>
  );
};

export default DownloadButton;
