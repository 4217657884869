import type { FC } from "react";
import React from "react";

import { Route, Navigate, Routes as _Routes, useLocation } from "react-router";

import Home from "pages/Home";
import Qna from "pages/Qna";
import TotalInfoPage from "pages/TotalInfoPage";

import Layout from "./RootLayout";

const LocationRedirect = () => {
  const location = useLocation();

  window.location.replace(`${process.env.REACT_APP_SHARE_URL}${location.pathname}`);

  return null;
};

const Routes: FC = () => {
  return (
    <_Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={<Home />} /* App Deeplink */ />
        <Route path="/qna" element={<Qna />} />
        <Route path="/total-info" element={<TotalInfoPage />} />
        <Route path="/price_result/*" element={<LocationRedirect />} />
        <Route path="/inspectors/*" element={<LocationRedirect />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </_Routes>
  );
};

export default Routes;
