/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import type { FC } from "react";

import { css } from "@emotion/react";

type ImageSlideProps = {
  src: string;
  duration?: number;
};

const wrapperStyle = css`
  width: 100%;
  height: 100%;

  background-repeat: repeat-x;
  background-position-x: 0;
  background-position-y: center;
  background-size: auto 100%;
`;

const ImageSlide: FC<ImageSlideProps> = ({ src, duration = 30_000 }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [imageWidth, setImageWidth] = useState(0);

  const slideKeyframeStyle = css`
    @keyframes slide {
      from {
        background-position-x: 0;
      }

      to {
        background-position-x: -${imageWidth}px;
      }
    }
  `;

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      if (!wrapperRef.current) {
        return;
      }

      setImageWidth(img.width * (wrapperRef.current.clientHeight / img.height));
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      css={[
        wrapperStyle,
        { backgroundImage: `url(${src})` },
        imageWidth && [slideKeyframeStyle, { animation: `slide ${duration}ms linear infinite` }],
      ]}
    />
  );
};

export default ImageSlide;
