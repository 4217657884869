import React from "react";

import classNames from "classnames";

import Typography from "../../../../design-system/Typography/Typography";
import DealerProfileCarousel from "../DealerProfileCarousel/DealerProfileCarousel";

import styles from "./Section5Mobile.module.scss";

interface Props {
  sectionInView: boolean;
}

const Section5Mobile: React.FC<Props> = ({ sectionInView }) => {
  return (
    <>
      <div className={classNames(styles.mobileContent, { [styles.view]: sectionInView }, "mobile")}>
        <h2 className={styles.mobileTitle}>
          검증된 딜러와 <br />
          집앞거래
        </h2>
        <Typography variant="Body_1" className={styles.subTitle}>
          헤이딜러는 감가내역까지 투명하게 공개
        </Typography>
      </div>
      <div className={classNames(styles.carousel, { [styles.view]: sectionInView }, "mobile")}>
        <DealerProfileCarousel />
      </div>
    </>
  );
};

export default Section5Mobile;
