/** @jsxImportSource @emotion/react */
import React from "react";
import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";

import { customTypographyStyles } from "../styles";

type ReviewItemProps = {
  iconSrc: string;
  title: string;
  caption: string;
  content: string;
};

const wrapperStyle = css`
  display: grid;

  gap: 1.5rem;

  align-content: start;
  justify-items: center;

  padding: 2rem 1.5rem;

  background-color: ${colors.base_gray3};
  border-radius: 1.25rem;
`;

const ReviewItem: FC<ReviewItemProps> = ({ iconSrc, title, caption, content }) => {
  return (
    <div css={wrapperStyle}>
      <img src={iconSrc} css={{ width: "4.5rem", height: "4.5rem" }} />
      <div css={{ display: "grid", gap: "0.5rem" }}>
        <div css={[customTypographyStyles.subtitle_1_fit, { whiteSpace: "pre-wrap", textAlign: "center" }]}>
          {title}
        </div>
        <Typography variant="caption_1" customCSS={{ textAlign: "center" }}>
          {caption}
        </Typography>
      </div>
      <Typography variant="body_1" preWrap>
        {content}
      </Typography>
    </div>
  );
};

export default ReviewItem;
