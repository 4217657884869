import React from "react";

import classNames from "classnames";
import { useInView } from "react-intersection-observer";

import DesktopSection3 from "images/desktop-section3.png";
import MobileSection3 from "images/mobile-section3.png";

import Typography from "../../../design-system/Typography/Typography";
import { isIEOrEdge } from "../../../hooks/isIEOrEdge";

import styles from "./Section3.module.scss";

const Section3: React.FC = () => {
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <section className={styles.section} ref={sectionRef}>
      <>
        <div className={classNames(styles.mobileContent, { [styles.view]: sectionInView }, "mobile")}>
          <h2 className={classNames(styles.mobileTitle)}>
            번호판으로 <br />
            쉽게 시세조회
          </h2>
          <Typography variant="Body_1" className={styles.subTitle}>
            100만대+ 데이터로 정확하게
          </Typography>
        </div>

        <img
          loading="lazy"
          src={MobileSection3}
          alt="헤이딜러 시세조회 화면"
          className={classNames(styles.section3MobileImage, { [styles.imageView]: sectionInView }, "mobile")}
        />
      </>
      <>
        <div className={classNames(styles.leftSection, "desktop")}>
          <div
            className={classNames(styles.content, { [styles.view]: sectionInView, [styles.isIEOrEdge]: isIEOrEdge() })}
          >
            <h2 className={styles.desktopTitle}>
              번호판으로 <br />
              쉽게 시세조회
            </h2>
            <h4 className={styles.desktopSubTitle}>100만대+ 데이터로 정확하게</h4>
          </div>
        </div>
        <div className={classNames(styles.rightSection, "desktop")}>
          <img
            loading="lazy"
            src={DesktopSection3}
            alt="헤이딜러 시세조회 화면"
            className={classNames(styles.desktopSection3Image, {
              [styles.view]: sectionInView,
              [styles.isIEOrEdge]: isIEOrEdge(),
            })}
          />
        </div>
      </>
    </section>
  );
};

export default Section3;
