/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import React, { useState } from "react";

import { css } from "@emotion/react";
import { colors, IconButton, StrokeMenuIcon } from "@PRNDcompany/heydealer-ui";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import { ReactComponent as Logo } from "assets/img/logo.svg";

import SideMenu from "./SideMenu";

const headerStyle = css`
  position: sticky;
  top: 0;
  z-index: 1;

  box-sizing: border-box;
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0.25rem 0.75rem;

  background-color: ${colors.base_white}E6;
`;

const sideMenuOverlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const dimmerStyle = css`
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
`;

const menuStyle = css`
  position: absolute;
  top: 0;
  z-index: 1;

  height: 100%;
`;

const MobileHeader: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuIconClick = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const transitions = useTransition(isMenuOpen, {
    from: { progress: 0 },
    enter: { progress: 1 },
    leave: { progress: 0 },
  });

  return (
    <>
      <header css={headerStyle}>
        <Link to="/" css={{ padding: "0 0.5rem" }}>
          <Logo css={{ width: "auto", height: "2.5rem" }} />
        </Link>
        <IconButton icon={StrokeMenuIcon} size={24} onClick={handleMenuIconClick} touchAreaSize={48} />
      </header>
      {transitions(
        ({ progress }, isOpen) =>
          isOpen && (
            <div css={sideMenuOverlayStyle}>
              <animated.div style={{ opacity: progress }} css={dimmerStyle} onClick={() => setIsMenuOpen(false)} />
              <animated.div style={{ right: progress.to((val) => `${(val - 1) * 100}%`) }} css={menuStyle}>
                <SideMenu onClose={() => setIsMenuOpen(false)} />
              </animated.div>
            </div>
          )
      )}
    </>
  );
};

export default MobileHeader;
