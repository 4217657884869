export const base_primary = "rgba(39, 46, 64, 1)";
export const base_white = "rgba(255, 255, 255, 1)";
export const base_gray6 = "rgba(134, 154, 183, 1)";
export const base_16 = "rgba(39, 46, 64, 0.16)";
export const base_32 = "rgba(39, 46, 64, 0.32)";
export const base_8 = "rgba(39, 46, 64, 0.08)";
export const base_dark = "rgba(16, 19, 26, 1)";
export const base_gray1 = "rgba(251, 252, 255, 1)";
export const base_gray2 = "rgba(247, 248, 251, 1)";
export const base_gray3 = "rgba(233, 237, 244, 1)";
export const base_gray4 = "rgba(204, 216, 232, 1)";
export const base_gray5 = "rgba(175, 194, 219, 1)";
export const base_light = "rgba(65, 77, 107, 1)";
export const brandcolor_16 = "rgba(57, 110, 255, 0.16)";
export const brandcolor_32 = "rgba(57, 110, 255, 0.32)";
export const brandcolor_8 = "rgba(57, 110, 255, 0.08)";
export const brandcolor_dark = "rgba(51, 98, 228, 1)";
export const brandcolor_light = "rgba(96, 139, 255, 1)";
export const brandcolor_primary = "rgba(57, 110, 255, 1)";
export const caution_16 = "rgba(245, 185, 35, 0.16)";
export const caution_32 = "rgba(245, 185, 35, 0.32)";
export const caution_8 = "rgba(245, 185, 35, 0.08)";
export const caution_dark = "rgba(245, 159, 0, 1)";
export const caution_light = "rgba(255, 193, 79, 1)";
export const caution_primary = "rgba(255, 176, 32, 1)";
export const success_16 = "rgba(34, 226, 172, 0.16)";
export const success_32 = "rgba(34, 226, 172, 0.32)";
export const success_8 = "rgba(34, 226, 172, 0.08)";
export const success_dark = "rgba(26, 197, 149, 1)";
export const success_light = "rgba(97, 234, 196, 1)";
export const success_primary = "rgba(34, 226, 172, 1)";
export const warning_16 = "rgba(255, 88, 93, 0.16)";
export const warning_32 = "rgba(255, 88, 93, 0.32)";
export const warning_8 = "rgba(255, 88, 93, 0.08)";
export const warning_dark = "rgba(255, 67, 67, 1)";
export const warning_light = "rgba(255, 127, 127, 1)";
export const warning_primary = "rgba(255, 88, 93, 1)";
