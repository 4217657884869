/** @jsxImportSource @emotion/react */
import React from "react";
import type { FC } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

import ShowInView from "components/ShowInView";

import inspectionReportBg from "../assets/bg/inspection_report.png";
import carHistoryImg from "../assets/img/car_history.png";
import inspectionReportImg from "../assets/img/inspection_report.png";
import mockupScreenImg from "../assets/img/mockup_screen.gif";
import ImageSlide from "../components/ImageSlide";
import Mockup from "../components/Mockup";
import { reviews } from "../constants/reviews";

import maintenanceHistoryImg from "./assets/img/maintenance_history.png";
import ReviewItem from "./components/ReviewItem";
import { customTypographyStyles } from "./styles";

const sectionStyle = css`
  position: relative;

  width: 60rem;
  margin: 0 auto;
`;

const DesktopTotalInfoPage: FC = () => {
  return (
    <div>
      <div css={{ backgroundColor: "#000000f5" }}>
        <section css={sectionStyle}>
          <ShowInView>
            <div css={{ display: "grid", gap: "1.5rem", padding: "12.5rem 0 18.75rem" }}>
              <div css={[customTypographyStyles.subtitle_1_fit, { color: colors.brand_dark }]}>
                중고차 숨은이력 찾기
              </div>
              <div css={[customTypographyStyles.headline_title, { color: colors.base_white }]}>
                구매할 중고차, <br />
                번호만 입력하세요
              </div>
            </div>
          </ShowInView>
          <div
            css={{
              width: "21.5rem",
              height: "35.75rem",
              position: "absolute",
              bottom: 0,
              right: "-0.25rem",
              overflow: "hidden",
            }}
          >
            <Mockup screenSrc={mockupScreenImg} />
          </div>
        </section>
      </div>
      <div css={{ backgroundColor: colors.base_gray3 }}>
        <section css={sectionStyle}>
          <ShowInView>
            <div css={{ display: "grid", gap: "1.5rem", padding: "17.5rem 0 17.75rem", color: colors.base_primary }}>
              <div css={customTypographyStyles.headline_0}>
                <div>어디에도 없던</div>
                <div css={{ color: colors.brand_primary }}>숨은 정비 이력</div>
              </div>
              <div css={[customTypographyStyles.body_0, { color: colors.base_light }]}>
                흩어져 있는 정비 이력을 한 곳에 모아놨어요.
              </div>
            </div>
          </ShowInView>
          <img
            src={maintenanceHistoryImg}
            css={{
              width: "26.75rem",
              height: "39.5rem",
              position: "absolute",
              bottom: 0,
              right: "-1.75rem",
              objectFit: "cover",
              objectPosition: "center top",
            }}
          />
        </section>
      </div>
      <div css={{ backgroundColor: colors.base_gray2 }}>
        <div css={{ position: "relative", width: "100%", height: "100%", zIndex: 0 }}>
          <section css={sectionStyle}>
            <ShowInView>
              <div
                css={{
                  display: "grid",
                  gap: "1.5rem",
                  padding: "17.5rem 0 17.75rem",
                  color: colors.base_primary,
                }}
              >
                <div css={customTypographyStyles.headline_0}>
                  <div css={{ color: colors.brand_primary }}>19가지 차량 이력</div>
                  <div>투명하게 공개</div>
                </div>
                <div css={customTypographyStyles.body_0}>구매할 차량의 모든 이력을 확인해보세요.</div>
              </div>
            </ShowInView>
            <div css={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, zIndex: -1 }}>
              <div
                css={{
                  position: "absolute",
                  width: "32.5rem",
                  height: "100%",
                  top: 0,
                  left: 0,
                  background: `linear-gradient(90deg, ${colors.base_gray2}FF 0%, ${colors.base_gray2}FF 83.98%, ${colors.base_gray2}00 100%)`,
                  zIndex: 1,
                }}
              />
              <div
                css={[
                  {
                    height: "22.5rem",
                    position: "absolute",
                    left: "26rem",
                    width: `calc(100vw / 2 + 4rem)`,
                    top: "14.5rem",
                    overflow: "hidden",
                    zIndex: 0,
                  },
                ]}
              >
                <ImageSlide src={carHistoryImg} />
              </div>
            </div>
          </section>
        </div>
      </div>
      <div
        css={{
          backgroundImage: `linear-gradient(#000000C2, #000000C2), url(${inspectionReportBg})`,
          backgroundSize: "cover",
        }}
      >
        <section css={sectionStyle}>
          <ShowInView>
            <div css={{ display: "grid", gap: "1.5rem", padding: "17.5rem 0 17.75rem", color: colors.base_white }}>
              <div css={customTypographyStyles.headline_0}>
                <div>전문가가 작성한</div>
                <div css={{ color: colors.brand_primary }}>하부 점검 리포트</div>
              </div>
              <div css={customTypographyStyles.body_0}> 숨겨진 누유, 부식, 파손 여부도 알 수 있어요.</div>
            </div>
          </ShowInView>
          <div
            css={{
              width: "21.5rem",
              height: "33.875rem",
              position: "absolute",
              bottom: 0,
              right: "-1.75rem",
              overflow: "hidden",
            }}
          >
            <Mockup screenSrc={inspectionReportImg} />
          </div>
        </section>
      </div>
      <div css={{ backgroundColor: colors.base_gray2 }}>
        <section css={sectionStyle}>
          <ShowInView>
            <div css={{ display: "grid", gap: "4.75rem", padding: "7.25rem 0" }}>
              <div css={[customTypographyStyles.headline_0, { textAlign: "center" }]}>구매 전에 꼭 확인하세요</div>
              <div css={{ display: "grid", gap: "1.5rem", gridTemplateColumns: "repeat(3, 1fr)" }}>
                {reviews.map((review) => (
                  <ReviewItem key={review.title} {...review} />
                ))}
              </div>
            </div>
          </ShowInView>
        </section>
      </div>
    </div>
  );
};

export default DesktopTotalInfoPage;
