import React from "react";

import classNames from "classnames";

import Typography from "../../../../design-system/Typography/Typography";
import MobileSection4 from "../../../../images/mobile-section4.png";

import styles from "./Section4Mobile.module.scss";

interface Props {
  sectionInView: boolean;
}

const Section4Mobile: React.FC<Props> = ({ sectionInView }) => {
  return (
    <div className={classNames(styles.mobileContent, { [styles.view]: sectionInView }, "mobile")}>
      <h2 className={styles.mobileTitle}>
        좋은 값에 <br />
        팔고 싶다면,
      </h2>
      <Typography variant="Body_1" className={styles.subTitle}>
        전국 매입딜러 90%가 참여하는 경매
      </Typography>
      <Typography variant="Caption" className={styles.caption}>
        *연락처는 판매요청 전까지 딜러에게 공개되지 않아요.
      </Typography>
      <img
        loading="lazy"
        src={MobileSection4}
        alt="헤이딜러 시세조회 화면"
        className={classNames(styles.section4MobileImage, { [styles.imageView]: sectionInView })}
      />
    </div>
  );
};

export default Section4Mobile;
