import ReactGA from "react-ga4";

type EventAnalytics = Partial<{
  ga: typeof ReactGA;
}>;

export const eventAnalytics: EventAnalytics = {
  ga: undefined,
};

export const initEventAnalytics = (id: Partial<Record<keyof EventAnalytics, string>>) => {
  if (!eventAnalytics.ga && id.ga) {
    ReactGA.initialize([{ trackingId: id.ga }]);
    eventAnalytics.ga = ReactGA;
  }
};
