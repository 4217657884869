import type { FC } from "react";
import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";

import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";
import useIsMobile from "hooks/useIsMobile";

const RootLayout: FC = () => {
  const isMobile = useIsMobile();

  const { storeParams } = useApplink(MKT_PROJECT_KEY);

  useEffect(() => {
    storeParams({ defaultTemplateId: "QbFw", defaultURLId: isMobile ? "HomepageMobile" : "HomepagePC" });
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default RootLayout;
