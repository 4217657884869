/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import React from "react";

import { Typography } from "@PRNDcompany/heydealer-ui";
import * as easings from "d3-ease";
import { animated, useSpring } from "react-spring";

const TradedCarCount: FC = () => {
  const today = new Date();
  const standardDay = new Date(2021, 7, 18);
  const diffDays = Math.ceil((today.getTime() - standardDay.getTime()) / (1000 * 3600 * 24));
  const extra = Math.random() * 200 + 311;

  /** 하루에 대략 412대 거래 된다고 가정 **/
  const countStyleProps = useSpring({
    to: { count: 433421 + diffDays * 412 + extra },
    from: { count: 0 },
    config: {
      friction: 30,
      easing: easings.easeCircleOut,
    },
  });

  return (
    <div css={{ display: "grid", gap: "0.5rem", gridAutoFlow: "column" }}>
      <Typography variant="body_1">누적거래</Typography>
      <Typography variant="subtitle_2" customCSS={{ width: "4.75rem", textAlign: "right" }}>
        <animated.span>{countStyleProps.count.to((number) => Math.round(number).toLocaleString())}</animated.span>대
      </Typography>
    </div>
  );
};

export default TradedCarCount;
