import React from "react";

import classNames from "classnames";

import partyPopper from "../../images/party-popper.png";

import styles from "./Emoji.module.scss";

const variants = {
  "party-popper": partyPopper,
};

export type EmojiVariants = keyof typeof variants;

interface Props {
  size?: number;
  name: EmojiVariants;
  className?: string;
}

const Emoji: React.FC<Props> = ({ size, name, className }) => {
  return (
    <img src={variants[name]} className={classNames(styles.emoji, className)} style={{ width: size, height: size }} />
  );
};

export default Emoji;
