import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { typographyStyles } from "@PRNDcompany/heydealer-ui";

// 개발 편의를 위해 따로 추가; key 임의 지정하였음
type CustomTypographyStyleKey = "headline_title" | "headline_0" | "subtitle_1_fit" | "body_0";

export const customTypographyStyles: Record<CustomTypographyStyleKey, Interpolation<Theme>> = {
  headline_title: [
    typographyStyles.headline_1,
    css`
      font-size: 3.5rem;
      line-height: 4.625rem;

      -webkit-text-stroke: ${0.3 / 16}rem currentColor;
    `,
  ],
  headline_0: [
    typographyStyles.headline_1,
    css`
      font-size: 3rem;
      line-height: 4.5rem;

      -webkit-text-stroke: ${0.3 / 16}rem currentColor;
    `,
  ],
  subtitle_1_fit: [
    typographyStyles.subtitle_1,
    css`
      line-height: 1.75rem;
    `,
  ],
  body_0: [
    typographyStyles.body_1,
    css`
      font-size: 1.25rem;
      line-height: 1.75rem;
    `,
  ],
};
