/** @jsxImportSource @emotion/react */
import type { FC, HTMLAttributeAnchorTarget, MouseEventHandler, PropsWithChildren } from "react";
import React from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";
import { Link } from "react-router-dom";

type MenuItemProps = PropsWithChildren<
  { target?: HTMLAttributeAnchorTarget; onClick?: MouseEventHandler<HTMLAnchorElement> } & (
    | { external?: false; to: string; href?: never }
    | { external: true; to?: never; href: string }
  )
>;

const wrapperStyle = css`
  box-sizing: border-box;
  display: inline-flex;

  align-items: center;

  height: 3rem;
  padding: 0.75rem 1rem;

  cursor: pointer;

  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${colors.base_gray2};
    border-radius: 0.5rem;
  }
`;

const MenuItem: FC<MenuItemProps> = (props) => {
  const { external, onClick } = props;

  if (!external) {
    const { to, target, children } = props;

    return (
      <Link to={to} target={target} onClick={onClick} css={wrapperStyle}>
        {children}
      </Link>
    );
  }

  const { href, target, children } = props;

  return (
    <a href={href} target={target} onClick={onClick} css={wrapperStyle}>
      {children}
    </a>
  );
};

export default MenuItem;
