/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

import AppDownloadButtons from "./AppDownloadButtons/AppDownloadButtons";
import typoTextImg from "./assets/img/typo_text.png";

const typoTextImgStyle = css`
  position: absolute;
  top: 0;

  height: 100%;

  transform: translateX(-32%);
`;

const videoBgURL = "https://static.web.heydealer.com/heydealer-intro/desktop_section1_bg.mp4";

const DesktopSection1: FC = () => (
  <div css={{ backgroundColor: colors.base_dark, overflow: "hidden" }}>
    <div css={{ margin: "0 auto", width: "100%", maxWidth: "120rem" }}>
      <div css={{ position: "relative", boxSizing: "content-box", width: "100%", height: 0, paddingBottom: "56.25%" }}>
        <video
          muted
          playsInline
          loop
          autoPlay
          src={videoBgURL}
          css={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover" }}
        />
        <div css={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}>
          <div
            css={{
              width: "100%",
              maxWidth: "58.625rem",
              margin: "0 auto",
            }}
          >
            <img src={typoTextImg} css={typoTextImgStyle} />
            <div css={{ position: "absolute", bottom: "14.56%" }}>
              <AppDownloadButtons />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DesktopSection1;
