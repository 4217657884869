/** @jsxImportSource @emotion/react */
import React from "react";
import type { FC } from "react";

import { css } from "@emotion/react";
import { Button, colors, effects } from "@PRNDcompany/heydealer-ui";
import { useSpring, animated } from "@react-spring/web";

import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";

const wrapperStyle = css`
  padding: 1.25rem 1rem;

  background: ${colors.base_white};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: ${effects.layer_level_16};
`;

const StickyBottom: FC = () => {
  const { url } = useApplink(MKT_PROJECT_KEY);

  const springs = useSpring({
    from: { y: "100%" },
    to: { y: "0%" },
  });

  return (
    <animated.div style={springs} css={wrapperStyle}>
      <a css={{ all: "initial" }} href={url.toString()} target="_blank">
        <Button size={56} color="blue" arrow="right" fullWidth>
          🚙{"  "}지금 번호판 입력하기
        </Button>
      </a>
    </animated.div>
  );
};

export default StickyBottom;
