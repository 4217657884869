import { eventAnalytics } from "modules/eventAnalytics";

type LogClickHeaderMenuEventParams = {
  type: string;
};

export const logClickHeaderMenuEvent = (params: LogClickHeaderMenuEventParams) => {
  eventAnalytics.ga?.event("click_header_menu", params);
};

export const logClickHeaderMenuTotalInfoEvent = () => {
  logClickHeaderMenuEvent({ type: "total_info" });
  eventAnalytics.ga?.event("click_header_menu_total_info");
};

export const logClickHeaderMenuScrapEvent = () => {
  logClickHeaderMenuEvent({ type: "scrap" });
  eventAnalytics.ga?.event("click_header_menu_scrap");
};

export const logClickHeaderMenuQNAEvent = () => {
  logClickHeaderMenuEvent({ type: "qna" });
  eventAnalytics.ga?.event("click_header_menu_qna");
};

export const logClickHeaderMenuPRNDEvent = () => {
  logClickHeaderMenuEvent({ type: "prnd" });
  eventAnalytics.ga?.event("click_header_menu_prnd");
};
