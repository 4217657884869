import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { typographyStyles } from "@PRNDcompany/heydealer-ui";

// 개발 편의를 위해 따로 추가; key 임의 지정하였음
type CustomTypographyStyleKey = "headline_2_fit" | "subtitle_1_fit";

export const customTypographyStyles: Record<CustomTypographyStyleKey, Interpolation<Theme>> = {
  headline_2_fit: [
    typographyStyles.headline_2,
    css`
      font-size: 2rem;
      line-height: 3rem;

      -webkit-text-stroke: ${0.2 / 16}rem currentColor;
    `,
  ],
  subtitle_1_fit: [
    typographyStyles.subtitle_1,
    css`
      line-height: 1.75rem;
    `,
  ],
};
