import React from "react";

import { useInView } from "react-intersection-observer";

import Section5Desktop from "./Section5Desktop/Section5Desktop";
import Section5Mobile from "./Section5Mobile/Section5Mobile";

import styles from "./Section5.module.scss";

const Section5: React.FC = () => {
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <section className={styles.section} ref={sectionRef}>
      <Section5Mobile sectionInView={sectionInView} />
      <Section5Desktop sectionInView={sectionInView} />
    </section>
  );
};

export default Section5;
