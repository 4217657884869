/** @jsxImportSource @emotion/react */
import type { FC, PropsWithChildren, ReactNode } from "react";
import { useEffect, useRef } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";
import { pageview } from "react-ga";
import { useLocation } from "react-router-dom";

import Header from "components/Header";

import Footer from "../Footer/Footer";

const wrapperStyle = css`
  display: grid;

  grid-template-rows: auto minmax(auto, 1fr) auto;

  min-height: 100%;
`;

const stickyBottomStyle = css`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  z-index: 2;

  box-sizing: border-box;

  width: 100%;
`;

const spacerStyle = css`
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: calc(-1 * env(safe-area-inset-bottom));

  background-color: ${colors.base_gray3};
`;

type LayoutProps = PropsWithChildren<{
  stickyBottom?: ReactNode;
}>;

const Layout: FC<LayoutProps> = ({ children, stickyBottom }) => {
  const { key, pathname, search } = useLocation();
  const spacerRef = useRef<HTMLDivElement>(null);
  const stickyBottomRef = useRef<HTMLDivElement>(null);

  // TODO: stickyBottom body 내부로 portal 생성되도록 전환 후 마이그레이션 필요
  useEffect(() => {
    const spacerElement = spacerRef.current;
    const stickyBottomElement = stickyBottomRef.current;
    if (!spacerElement || !stickyBottomElement) {
      return;
    }

    spacerElement.style.height = `${stickyBottomElement.offsetHeight}px`;
    const observer = new ResizeObserver(() => {
      spacerElement.style.height = `${stickyBottomElement.offsetHeight}px`;
    });

    observer.observe(stickyBottomElement);

    return () => {
      observer.disconnect();
    };
  }, [spacerRef.current, stickyBottomRef.current]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      pageview(pathname + search);
    }
  }, [pathname, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [key]);

  return (
    <div css={wrapperStyle}>
      <Header />
      {children}
      <Footer />
      <div css={spacerStyle} ref={spacerRef} />
      {stickyBottom && (
        <div css={stickyBottomStyle} ref={stickyBottomRef}>
          {stickyBottom}
        </div>
      )}
    </div>
  );
};

export default Layout;
