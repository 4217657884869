/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import React from "react";

import { css } from "@emotion/react";
import { colors, IconButton, StrokeCloseIcon, Typography } from "@PRNDcompany/heydealer-ui";
import { Link } from "react-router-dom";

import {
  logClickHeaderMenuPRNDEvent,
  logClickHeaderMenuQNAEvent,
  logClickHeaderMenuScrapEvent,
  logClickHeaderMenuTotalInfoEvent,
} from "analytics";
import { ReactComponent as NewBadge } from "assets/img/new_badge.svg";
import { prndLink, scrapLink } from "config";
import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";

type SideMenuProps = {
  onClose: () => void;
};

const wrapperStyle = css`
  box-sizing: border-box;

  width: 17.5rem;
  height: 100%;

  background-color: ${colors.base_white};
`;

const navStyle = css`
  display: grid;

  gap: 3rem;

  align-content: start;

  padding: 2rem;
`;

const ulStyle = css`
  all: initial;

  display: grid;

  gap: 2rem;

  list-style: none;
`;

const SideMenu: FC<SideMenuProps> = ({ onClose }) => {
  const { url } = useApplink(MKT_PROJECT_KEY);

  return (
    <div css={wrapperStyle}>
      <header css={{ padding: "0.25rem 0.75rem", display: "flex", justifyContent: "flex-end" }}>
        <IconButton icon={StrokeCloseIcon} onClick={onClose} size={24} touchAreaSize={48} />
      </header>
      <nav css={navStyle}>
        <ul css={ulStyle}>
          <li>
            <Typography variant="subtitle_2">서비스</Typography>
          </li>
          <li>
            <a href={url.toString()} target="_blank">
              <Typography variant="body_1">앱 다운로드</Typography>
            </a>
          </li>
          <li>
            <Link to="/total-info" onClick={() => logClickHeaderMenuTotalInfoEvent()}>
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "auto 1rem",
                  gap: "0.25rem",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <Typography variant="body_1">중고차 숨은이력 찾기</Typography>
                <NewBadge css={{ width: "1rem", height: "1rem" }} />
              </div>
            </Link>
          </li>
          <li>
            <a href={scrapLink} target="_blank" onClick={() => logClickHeaderMenuScrapEvent()}>
              <Typography variant="body_1">폐차 비교견적</Typography>
            </a>
          </li>
          <li>
            <Link to="/qna" onClick={() => logClickHeaderMenuQNAEvent()}>
              <Typography variant="body_1">자주묻는 질문</Typography>
            </Link>
          </li>
        </ul>
        <ul css={ulStyle}>
          <li>
            <Typography variant="subtitle_2">회사</Typography>
          </li>
          <li>
            <a href={prndLink} target="_blank" onClick={() => logClickHeaderMenuPRNDEvent()}>
              <Typography variant="body_1">PRND 채용</Typography>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
