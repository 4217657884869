import React from "react";
import type { FC } from "react";

import MobileHeader from "components/Header/MobileHeader/MobileHeader";
import useIsMobile from "hooks/useIsMobile";

import DesktopHeader from "./DesktopHeader";

const Header: FC = () => {
  const isMobile = useIsMobile();

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};

export default Header;
