import type { FC } from "react";
import React from "react";

import { Global } from "@emotion/react";
import { GlobalStyle } from "@PRNDcompany/heydealer-ui";
import { initialize } from "react-ga";
import { BrowserRouter } from "react-router-dom";

import { initEventAnalytics } from "modules/eventAnalytics";
import Routes from "routes";

initialize(process.env.REACT_APP_GA_UA_ID || "");

initEventAnalytics({
  ga: process.env.REACT_APP_GA_ID,
});

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <Global styles={{ body: { width: "100%" } }} />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
};

export default App;
