import React from "react";

import classNames from "classnames";

import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";
import Appstore from "images/appstore.png";
import Appstore2 from "images/appstore2.png";
import GooglePlay from "images/google-play.png";
import GooglePlay2 from "images/google-play2.png";

import { desktopAppStoreLink, desktopGooglePlayLink } from "../../../../config";

import styles from "./AppDownloadButtons.module.scss";

interface Props {
  variant?: "main" | "qna";
}

const AppDownloadButtons: React.FC<Props> = ({ variant = "main" }) => {
  const { url } = useApplink(MKT_PROJECT_KEY);

  const googlePlayLinkURL = new URL(desktopGooglePlayLink);
  googlePlayLinkURL.search = url.search;
  const googlePlayLink = googlePlayLinkURL.toString();

  const appStoreLinkURL = new URL(desktopAppStoreLink);
  appStoreLinkURL.search = url.search;
  const appStoreLink = appStoreLinkURL.toString();

  return (
    <div>
      <a href={googlePlayLink} target="_blank" className={classNames(styles.download, styles[variant])}>
        <img
          src={variant === "main" ? GooglePlay : GooglePlay2}
          alt="구글 플레이 앱 다운로드 버튼"
          className={styles.googlePlay}
        />
      </a>
      <a href={appStoreLink} target="_blank" className={classNames(styles.download, styles[variant])}>
        <img
          src={variant === "main" ? Appstore : Appstore2}
          alt="앱 스토어 앱 다운로드 버튼"
          className={styles.appstore}
        />
      </a>
    </div>
  );
};

export default AppDownloadButtons;
