/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import { css } from "@emotion/react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";

import {
  logClickFooterMenuAppDownloadEvent,
  logClickFooterMenuPRNDEvent,
  logClickFooterMenuPrivacyPolicyEvent,
  logClickFooterMenuQNAEvent,
  logClickFooterMenuTermsOfServiceEvent,
} from "analytics";
import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";
import { ReactComponent as ArrowIcon } from "images/arrow-icon.svg";
import PRNDLogo from "images/prnd-logo.png";

import { desktopAppStoreLink, desktopGooglePlayLink, mailLink, prndLink } from "../../config";
import Typography from "../../design-system/Typography/Typography";

import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  const [isOpenAppDownMenu, setOpenAppDownMenu] = useState(false);
  const { url } = useApplink(MKT_PROJECT_KEY);

  const onClickDesktopAppDown = () => {
    logClickFooterMenuAppDownloadEvent();
    setOpenAppDownMenu((prev) => !prev);
  };

  const transitions = useTransition(isOpenAppDownMenu, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const googlePlayLinkURL = new URL(desktopGooglePlayLink);
  googlePlayLinkURL.search = url.search;
  const googlePlayLink = googlePlayLinkURL.toString();

  const appStoreLinkURL = new URL(desktopAppStoreLink);
  appStoreLinkURL.search = url.search;
  const appStoreLink = appStoreLinkURL.toString();

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div
          className={styles.navWrapper}
          css={css`
            ul {
              margin: 0;

              > li {
                list-style: none;
              }
            }
          `}
        >
          <nav>
            <ul>
              <li>
                <Typography variant={"Subtitle_3"} className={classNames(styles.subTitle3, "mobile")}>
                  서비스
                </Typography>
                <Typography variant={"Subtitle_2"} className={classNames(styles.subTitle3, "desktop")}>
                  서비스
                </Typography>
              </li>
              <li className={styles.navItem}>
                <a
                  href={url.toString()}
                  className={"mobile"}
                  target="_blank"
                  onClick={logClickFooterMenuAppDownloadEvent}
                >
                  <Typography variant={"Body_2"} className={styles.navItemText}>
                    앱 다운로드
                  </Typography>
                </a>
                <div className={classNames(styles.desktopAppDown, "desktop")} onClick={onClickDesktopAppDown}>
                  <Typography variant={"Body_1"} className={classNames(styles.navItemText, styles.desktopAppDownText)}>
                    앱 다운로드{" "}
                    <ArrowIcon className={classNames(styles.arrowIcon, { [styles.isOpenArrow]: isOpenAppDownMenu })} />
                  </Typography>
                  {transitions(
                    (appDownMenu, open) =>
                      open && (
                        <animated.div style={appDownMenu} className={styles.appDownMenu}>
                          <a href={googlePlayLink} target="_blank" className={styles.googleLink}></a>
                          <div className={styles.border} />
                          <a href={appStoreLink} target="_blank" className={styles.appleLink}></a>
                        </animated.div>
                      )
                  )}
                </div>
              </li>
              <li className={styles.navItem}>
                <Link to="/qna" onClick={logClickFooterMenuQNAEvent}>
                  <Typography variant={"Body_2"} className={classNames(styles.navItemText, "mobile")}>
                    자주묻는 질문
                  </Typography>
                  <Typography variant={"Body_1"} className={classNames(styles.navItemText, "desktop")}>
                    자주묻는 질문
                  </Typography>
                </Link>
              </li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                <Typography variant={"Subtitle_3"} className={classNames(styles.subTitle3, "mobile")}>
                  회사
                </Typography>
                <Typography variant={"Subtitle_2"} className={classNames(styles.subTitle3, "desktop")}>
                  회사
                </Typography>
              </li>
              <li className={styles.navItem}>
                <a href={prndLink} target="_blank" onClick={logClickFooterMenuPRNDEvent}>
                  <Typography variant={"Body_2"} className={classNames(styles.navItemText, "mobile")}>
                    PRND 채용
                  </Typography>
                  <Typography variant={"Body_1"} className={classNames(styles.navItemText, "desktop")}>
                    PRND 채용
                  </Typography>
                </a>
              </li>
              <li className={styles.navItem}>
                <a href={mailLink}>
                  <Typography variant={"Body_2"} className={classNames(styles.navItemText, "mobile")}>
                    제휴문의
                  </Typography>
                  <Typography variant={"Body_1"} className={classNames(styles.navItemText, "desktop")}>
                    제휴문의
                  </Typography>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.serviceInfo}>
          <div className={classNames(styles.company, "mobile")}>(주)피알앤디컴퍼니</div>
          <Typography variant="Subtitle_3" className={classNames(styles.desktopCompany, "desktop")}>
            (주)피알앤디컴퍼니
          </Typography>
          <div className={classNames(styles.menus, "mobile")}>
            <Typography variant={"Caption"} className={classNames(styles.privacyPolicyText)} css={{ marginTop: 8 }}>
              <span>
                <a
                  className={styles.link}
                  href="https://api.heydealer.com/posts/ERJ1ZakN/"
                  target="_blank"
                  onClick={logClickFooterMenuTermsOfServiceEvent}
                >
                  서비스 이용약관
                </a>
                <span className={styles.verticalBorder}>I</span>
                <a
                  className={styles.link}
                  href="https://api.heydealer.com/posts/ERJ1ZakN/"
                  target="_blank"
                  onClick={logClickFooterMenuPrivacyPolicyEvent}
                >
                  개인정보 처리방침
                </a>{" "}
                <br />
              </span>
              사업자등록번호 : 220-88-95088
              <span className={styles.verticalBorder}>I</span>
              1599-4783
              <span className={styles.verticalBorder}>I</span>
              대표자 : 박진우
            </Typography>
          </div>
          <div className={classNames(styles.menus, "mobile")}>
            <Typography variant={"Caption"} className={styles.privacyPolicyText}>
              서울 서초구 서초대로 74길 14, 8층 <span className={styles.verticalBorder}>I</span> contact@heydealer.com{" "}
              <span className={styles.verticalBorder}>I</span> 통신판매번호 : 제2021-서울서초-4295호
            </Typography>
          </div>
          <Typography variant="Caption" className={classNames(styles.menus, styles.privacyPolicyText, "mobile")}>
            <a className={styles.link} href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208895088" target="_blank">
              사업자정보확인
            </a>
            <span className={styles.verticalBorder}>I</span>
            <a className={styles.link} href="https://api.heydealer.com/posts/XYxvQgkR/" target="_blank">
              자동차매매업정보확인
            </a>
          </Typography>
          <div className={classNames(styles.menus, "desktop")}>
            <Typography variant={"Body_2"} className={styles.privacyPolicyText} css={{ marginTop: 8 }}>
              <a href="https://api.heydealer.com/posts/ERJ1ZakN/" target="_blank">
                <span className={styles.link}>서비스 이용약관</span>
                <span className={styles.verticalBorder}>I</span>
                <span className={styles.link}>개인정보 처리방침</span>
                <span className={styles.verticalBorder}>I</span>
              </a>
              사업자등록번호 : 220-88-95088
              <span className={styles.verticalBorder}>I</span>
              1599-4783
              <span className={styles.verticalBorder}>I</span>
              대표자 : 박진우
            </Typography>
          </div>
          <div className={classNames(styles.menus, "desktop")}>
            <Typography variant={"Body_2"} className={styles.privacyPolicyText}>
              서울 서초구 서초대로 74길 14, 8층 <span className={styles.verticalBorder}>I</span> contact@heydealer.com{" "}
              <span className={styles.verticalBorder}>I</span> 통신판매번호 : 제2021-서울서초-4295호
            </Typography>
          </div>
          <Typography variant="Body_2" className={classNames(styles.menus, styles.privacyPolicyText, "desktop")}>
            <a className={styles.link} href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208895088" target="_blank">
              사업자정보확인
            </a>
            <span className={styles.verticalBorder}>I</span>
            <a className={styles.link} href="https://api.heydealer.com/posts/XYxvQgkR/" target="_blank">
              자동차매매업정보확인
            </a>
          </Typography>
          <div className={styles.prnd}>
            <Typography variant={"Caption"} className={classNames(styles.gray, "mobile")}>
              {new Date().getFullYear()} ⓒ PRND all rights reserved.
            </Typography>
            <Typography variant={"Body_2"} className={classNames(styles.gray, "desktop")}>
              {new Date().getFullYear()} ⓒ PRND all rights reserved.
            </Typography>
            <img src={PRNDLogo} alt="피알앤디 로고" className={styles.prndLogo} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
