import React from "react";

import classNames from "classnames";

import { isIEOrEdge } from "../../../../hooks/isIEOrEdge";
import DealerProfile1 from "../../../../images/dealer-profile1.png";
import DealerProfile2 from "../../../../images/dealer-profile2.png";

import styles from "./Section5Desktop.module.scss";

interface Props {
  sectionInView: boolean;
}

const Section5Desktop: React.FC<Props> = ({ sectionInView }) => {
  return (
    <div className={styles.section5Desktop}>
      <div className={classNames(styles.leftSection, "desktop")}>
        <div
          className={classNames(styles.content, {
            [styles.view]: sectionInView,
            [styles.isIEOrEdge]: isIEOrEdge(),
          })}
        >
          <h2 className={styles.desktopTitle}>
            검증된 딜러와 <br />
            집앞거래
          </h2>
          <h4 className={styles.desktopSubTitle}>헤이딜러는 감가내역까지 투명하게 공개</h4>
        </div>
      </div>
      <div className={classNames(styles.rightSection, "desktop")}>
        <img
          loading="lazy"
          src={DealerProfile1}
          alt="딜러 프로필 사진"
          className={classNames(styles.dealerProfileImage1, {
            [styles.view]: sectionInView,
            [styles.isIEOrEdge]: isIEOrEdge(),
          })}
        />
        <img
          loading="lazy"
          src={DealerProfile2}
          alt="딜러 리뷰 사진"
          className={classNames(styles.dealerProfileImage2, {
            [styles.view]: sectionInView,
            [styles.isIEOrEdge]: isIEOrEdge(),
          })}
        />
      </div>
    </div>
  );
};

export default Section5Desktop;
