/** @jsxImportSource @emotion/react */
import React from "react";
import type { FC } from "react";

import { css } from "@emotion/react";

import mockupImg from "./assets/mockup.png";
import { ReactComponent as ScreenSVG } from "./assets/screen.svg";

type MockupProps = {
  screenSrc: string;
};

const screenImgStyle = css`
  position: absolute;
  top: 0;
  left: 0;

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  padding: 4%;
  clip-path: url("#mockup-screen");

  object-fit: contain;
  object-position: center top;
`;

const Mockup: FC<MockupProps> = ({ screenSrc }) => {
  return (
    <div css={{ position: "relative", isolation: "isolate", transform: "translateZ(0)" }}>
      <ScreenSVG />
      <img src={mockupImg} css={{ width: "100%", height: "auto" }} />
      <img src={screenSrc} css={screenImgStyle} />
    </div>
  );
};

export default Mockup;
