import React, { useEffect } from "react";

import classNames from "classnames";
import { Swiper } from "swiper";

import DealerProfileImage1 from "images/dealer-profile1.png";
import DealerProfileImage2 from "images/dealer-profile2.png";

import styles from "./DealerProfileCarousel.module.scss";

const DealerProfileCarousel: React.FC = () => {
  useEffect(() => {
    new Swiper(".swiper-container", {
      spaceBetween: 16,
    });
  }, []);

  return (
    <div className={styles.carousel}>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className={classNames(styles.firstImage, "swiper-slide")}>
            <img loading="lazy" src={DealerProfileImage1} alt="딜러 프로필 사진" className={styles.dealerProfile} />
          </div>
          <div className="swiper-slide">
            <img loading="lazy" src={DealerProfileImage2} alt="딜러 리뷰 사진" className={styles.dealerProfile} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerProfileCarousel;
