import React, { useEffect, useRef } from "react";

import styles from "./Ripple.module.scss";

const Ripple: React.FC = () => {
  const dummyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (dummyRef && dummyRef.current) {
      const containerEl = dummyRef.current.parentElement;
      if (containerEl) {
        containerEl.classList.add(styles.rippleParent);
        containerEl.addEventListener("touchstart", (e) => {
          const spanEl = document.createElement("span");
          const [touchEvent] = e.changedTouches;
          const x = touchEvent.pageX - containerEl.getBoundingClientRect().left;
          const y = touchEvent.pageY - (containerEl.getBoundingClientRect().top + window.scrollY);
          spanEl.classList.add(styles.rippleSpan);
          spanEl.style.top = `${y}px`;
          spanEl.style.left = `${x}px`;
          containerEl.appendChild(spanEl);
        });
      }
    }
  }, []);

  return <div ref={dummyRef} className={styles.dummy} />;
};

export default Ripple;
