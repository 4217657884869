import React from "react";

import classNames from "classnames";

import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";
import { ReactComponent as Download } from "images/download.svg";

import Button from "../../design-system/Button/Button";
import Typography from "../../design-system/Typography/Typography";
import AppDownloadButtons from "../sectoins/DesktopSection1/AppDownloadButtons/AppDownloadButtons";

import styles from "./OtherQuestion.module.scss";

const OtherQuestion: React.FC = () => {
  const { url } = useApplink(MKT_PROJECT_KEY);

  return (
    <div className={styles.otherQuestion}>
      <Typography variant={"Subtitle_2"} className={classNames(styles.title, "mobile")}>
        다른 궁금증이 있으신가요?
      </Typography>
      <Typography variant={"Subtitle_1"} className={classNames(styles.title, "desktop")}>
        다른 궁금증이 있으신가요?
      </Typography>
      <Typography variant={"Body_2"} className={classNames(styles.subTitle, "mobile")}>
        헤이딜러 앱을 설치하신 뒤, 채팅문의로 알려주세요. <br /> 헤이딜러 매니저가 1:1로 친절히 안내드릴게요.
      </Typography>
      <Typography variant={"Body_1"} className={classNames(styles.subTitle, "desktop")}>
        헤이딜러 앱을 설치하신 뒤, 채팅문의로 알려주세요. <br /> 헤이딜러 매니저가 1:1로 친절히 안내드릴게요.
      </Typography>
      <div className={styles.buttonWrapper}>
        <Button
          href={url.toString()}
          text={"앱 다운로드"}
          variant="secondaryGray"
          leftIcon={<Download className={styles.icon} />}
          className={classNames(styles.downloadButton, "mobile")}
        />
        <AppDownloadButtons variant="qna" />
      </div>
    </div>
  );
};

export default OtherQuestion;
