/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import React from "react";

import { colors, Typography } from "@PRNDcompany/heydealer-ui";

import ShowInView from "components/ShowInView";
import useIsMobile from "hooks/useIsMobile";

const IntroSection: FC = () => {
  const isMobile = useIsMobile();

  return (
    <div css={{ background: colors.base_gray2 }}>
      <ShowInView>
        <div
          css={[
            { display: "grid", gap: "1rem" },
            isMobile
              ? { padding: "4.5rem 2rem" }
              : { padding: "7.25rem 0", justifyItems: "center", textAlign: "center" },
          ]}
        >
          <Typography variant={isMobile ? "subtitle_1" : "headline_3"} color="base_gray6">
            어렵고 불편하던 중고차 거래,
            <br />
            헤이딜러가 바꿔나갑니다.
          </Typography>
          <Typography variant={isMobile ? "subtitle_1" : "headline_3"}>
            모든 걸 세로로 보는 세상, <br />
            그에 맞는 새로운 기술로 <br />
            중고차 세로고침
          </Typography>
        </div>
      </ShowInView>
    </div>
  );
};

export default IntroSection;
