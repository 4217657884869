/** @jsxImportSource @emotion/react */
import type { FC, PropsWithChildren } from "react";
import React from "react";

import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";

type ShowInViewProps = PropsWithChildren<unknown>;

const wrapperStyle = css`
  opacity: 0;

  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateY(40px);
`;

const viewStyle = css`
  opacity: 1;

  transform: translateY(0);
`;

const ShowInView: FC<ShowInViewProps> = ({ children }) => {
  const [wrapperRef, isInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={wrapperRef} css={[wrapperStyle, isInView && viewStyle]}>
      {children}
    </div>
  );
};

export default ShowInView;
