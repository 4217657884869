/** @jsxImportSource @emotion/react */

import type { FC } from "react";
import React from "react";

import { css } from "@emotion/react";
import { Typography, colors, effects } from "@PRNDcompany/heydealer-ui";
import { useSpring, animated } from "@react-spring/web";
import QRCode from "react-qr-code";

import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";

const wrapperStyle = css`
  padding: 1.5rem 0;

  background: ${colors.base_white};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: ${effects.layer_level_32};
`;

const contentStyle = css`
  display: flex;

  align-items: center;
  justify-content: space-between;

  max-width: 58.625rem;
  margin: 0 auto;

  color: ${colors.base_primary};
`;

const qrWrapperStyle = css`
  box-sizing: border-box;

  width: 6rem;
  height: 6rem;
  padding: 0.5rem;

  background: ${colors.base_white};
`;

const StickyBottom: FC = () => {
  const { url } = useApplink(MKT_PROJECT_KEY);

  const springs = useSpring({
    from: { y: "100%" },
    to: { y: "0%" },
  });

  return (
    <animated.div style={springs} css={wrapperStyle}>
      <div css={contentStyle}>
        <div css={{ display: "grid", gap: "0.5rem" }}>
          <Typography variant="subtitle_1">휴대전화로 QR코드를 찍고 숨은이력을 확인해보세요. 🔍</Typography>
          <Typography variant="body_1">번호판만 입력하면 끝!</Typography>
        </div>
        <div css={qrWrapperStyle}>
          <QRCode value={url.toString()} css={{ width: "100%", height: "auto" }} href={url.toString()} />
        </div>
      </div>
    </animated.div>
  );
};

export default StickyBottom;
