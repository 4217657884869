/** @jsxImportSource @emotion/react */
import React from "react";

import { BidZeroLogo, colors, typographyStyles } from "@PRNDcompany/heydealer-ui";

import inspectionVideoMp4 from "assets/img/inspection.mp4";
import ShowInView from "components/ShowInView";
import useIsMobile from "hooks/useIsMobile";
import useVideoBackground from "hooks/useVideoBackground";

import Emoji from "../../Emoji/Emoji";

const ZeroSection: React.FC = () => {
  const isMobile = useIsMobile();
  const { canvasRef, videoRef, background } = useVideoBackground(colors.base_gray3);

  return (
    <div css={{ background }}>
      <ShowInView>
        <div
          css={[
            { display: "grid" },
            isMobile
              ? { padding: "4.5rem 2rem 2rem" }
              : {
                  padding: "7.125rem 0 6.5rem",
                  margin: "0 auto",
                  width: "fit-content",
                  justifyItems: "center",
                },
          ]}
        >
          <div css={[{ display: "grid", gap: "1rem" }, !isMobile && { textAlign: "center" }]}>
            <div
              css={[
                { fontWeight: "bold", display: "flex", color: colors.base_dark },
                isMobile
                  ? { fontSize: "2rem", lineHeight: "3rem", flexDirection: "column" }
                  : { fontSize: "3rem", lineHeight: "4.5rem", flexDirection: "row" },
              ]}
            >
              <span css={{ display: "flex", alignItems: "center" }}>
                헤이딜러
                <BidZeroLogo
                  css={[
                    { display: "inline-block", marginLeft: "0.5rem", height: "auto" },
                    isMobile ? { width: "5.625rem" } : { width: "8.75rem" },
                  ]}
                />
                &nbsp;
              </span>
              <span>
                출시&nbsp;
                <Emoji name="party-popper" size={isMobile ? 32 : 48} />
              </span>
            </div>
            <div
              css={[
                { color: colors.base_light },
                isMobile ? typographyStyles.body_1 : { fontSize: "1.25rem", lineHeight: "1.75rem" },
              ]}
            >
              감가Zero 내차팔기, 헤이딜러가 시작합니다.
            </div>
          </div>
          <div>
            <video autoPlay muted playsInline loop src={inspectionVideoMp4} ref={videoRef} hidden />
            <canvas ref={canvasRef} css={[{ width: "auto" }, isMobile ? { width: "100%" } : { height: "18.75rem" }]} />
          </div>
        </div>
      </ShowInView>
    </div>
  );
};

export default ZeroSection;
