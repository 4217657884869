import type { ReactNode } from "react";
import React, { Fragment, useEffect, useState } from "react";

import { ResizeObserver } from "@juggle/resize-observer";
import classNames from "classnames";
import useMeasure from "react-use-measure";

import { ReactComponent as ArrowIcon } from "images/arrow-icon.svg";
import { ReactComponent as Q } from "images/q.svg";

import Typography from "../../design-system/Typography/Typography";
import { usePrevious } from "../../hooks/usePrevious";
import Ripple from "../Ripple/Ripple";

import styles from "./QnAItem.module.scss";

interface Props {
  title: string;
  content: ReactNode[];
  collapseNumber: number;
  openCollapse: number | null;
  setOpenCollapse: (collapse: number | null) => void;
  className?: string;
}

const QnAItem: React.FC<Props> = ({ title, content, collapseNumber, openCollapse, setOpenCollapse, className }) => {
  const [isOpen, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const { height } = bounds;
  const previousOpenCollapse = usePrevious(openCollapse);

  const onClick = () => {
    if (previousOpenCollapse === collapseNumber) {
      setOpen((prev) => !prev);
    } else {
      setOpenCollapse(collapseNumber);
    }
  };

  useEffect(() => {
    if (openCollapse === collapseNumber) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openCollapse]);

  useEffect(() => {
    setContentHeight(height);
    window.addEventListener("resize", () => setContentHeight(height));

    return window.removeEventListener("resize", () => setContentHeight(height));
  }, [height]);

  return (
    <div className={classNames(styles.qnaCollapse, { [styles.isOpen]: isOpen }, className)}>
      <div className={styles.titleWrapper} onClick={onClick}>
        <div className={styles.title}>
          <Q className={styles.icon} />
          <Typography variant="Body_1" className={"mobile"}>
            {title}
          </Typography>
          <Typography variant="Body_1" className={classNames(styles.desktopTitle, "desktop")}>
            {title}
          </Typography>
        </div>
        <ArrowIcon className={styles.arrowIcon} />
        <Ripple />
      </div>
      <div
        className={styles.contentWrapper}
        style={isOpen ? { height: contentHeight, opacity: 1 } : { height: 0, opacity: 0 }}
      >
        <div className={styles.content} ref={ref}>
          {content.map((line, idx) => (
            <Fragment key={idx}>
              <Typography variant="Body_1" className={"mobile"}>
                {line}
              </Typography>
              <div className={classNames(styles.desktopContent, "desktop")}>{line}</div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QnAItem;
