import React from "react";

import classNames from "classnames";

import Layout from "../../components/Layout/Layout";
import OtherQuestion from "../../components/OtherQuestion/OtherQuestion";
import QnA from "../../components/QnA/QnA";
import Typography from "../../design-system/Typography/Typography";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Typography variant="Headline_3" className={classNames(styles.mobileTitle, "mobile")}>
            자주묻는 질문
          </Typography>
          <h1 className={classNames(styles.desktopTitle, "desktop")}>자주묻는 질문</h1>
        </div>
        <QnA />
        <div className={styles.br} />
        <OtherQuestion />
      </div>
    </Layout>
  );
};

export default Index;
