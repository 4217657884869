import { useEffect, useState } from "react";

import { DESKTOP_MIN_WIDTH_PX } from "constants/index";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < DESKTOP_MIN_WIDTH_PX);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(document.body.clientWidth < DESKTOP_MIN_WIDTH_PX);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export default useIsMobile;
