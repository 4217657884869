import React, { useEffect } from "react";

import Swiper from "swiper";

import SafeTradeCard from "../SafeTradeCard/SafeTradeCard";
import { safeTradeCards } from "../Section6";

import styles from "./SafeTradeCarousel.module.scss";

const SafeTradeCarousel: React.FC = () => {
  useEffect(() => {
    new Swiper(".swiper-container", {
      spaceBetween: 12,
    });
  }, []);

  return (
    <div className={styles.carousel}>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {safeTradeCards.map((safeTradeCard, index) => (
            <div className="swiper-slide" key={index}>
              <SafeTradeCard key={index} safeTradeCard={safeTradeCard} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SafeTradeCarousel;
