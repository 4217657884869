import React from "react";

import classNames from "classnames";

import Typography from "../../../../design-system/Typography/Typography";
import { isIEOrEdge } from "../../../../hooks/isIEOrEdge";
import DesktopSection4 from "../../../../images/desktop-section4.png";

import styles from "./Section4Desktop.module.scss";

interface Props {
  sectionInView: boolean;
}

const Section4Desktop: React.FC<Props> = ({ sectionInView }) => {
  return (
    <div className={classNames(styles.section4Desktop, "desktop")}>
      <div className={classNames(styles.leftSection)}>
        <img
          loading="lazy"
          src={DesktopSection4}
          alt="헤이딜러 시세조회 화면"
          className={classNames(styles.desktopSection4Image, {
            [styles.view]: sectionInView,
            [styles.isIEOrEdge]: isIEOrEdge(),
          })}
        />
      </div>
      <div className={classNames(styles.rightSection)}>
        <div
          className={classNames(styles.content, { [styles.view]: sectionInView, [styles.isIEOrEdge]: isIEOrEdge() })}
        >
          <h2 className={styles.desktopTitle}>
            좋은 값에 <br />
            팔고 싶다면,
          </h2>
          <h4 className={styles.desktopSubTitle}>전국 매입딜러 90%가 참여하는 경매</h4>
          <Typography variant={"Body_2"} className={styles.desktopCaption}>
            *연락처는 판매요청 전까지 딜러에게 공개되지 않아요.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Section4Desktop;
