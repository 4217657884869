/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

import TradedCarCount from "components/TradedCarCount";

import modelImg from "./assets/img/model.png";
import typoTextImg from "./assets/img/typo_text.png";

const wrapperStyle = css`
  position: relative;

  display: flex;

  align-items: stretch;
  justify-content: end;

  width: 100%;
  overflow: hidden;
`;

const MobileSection1: FC = () => (
  <div css={wrapperStyle}>
    <img src={modelImg} css={{ width: "100%", minWidth: "32.8125rem" }} />
    <img
      src={typoTextImg}
      css={{
        position: "absolute",
        top: "2rem",
        left: "2rem",
        width: "calc(100% / 3)",
        maxWidth: "12.7875rem",
        height: "auto",
      }}
    />
    <div css={{ position: "absolute", bottom: "1.75rem", left: "2rem", color: colors.base_dark }}>
      <TradedCarCount />
    </div>
  </div>
);

export default MobileSection1;
