import accidentImg from "../assets/img/accident.png";
import repairImg from "../assets/img/repair.png";
import sunImg from "../assets/img/sun.png";
import type { Review } from "../types";

export const reviews: Review[] = [
  {
    iconSrc: repairImg,
    title: "“몰랐던 정비이력을\n발견했어요”",
    caption: "19버1864 조회 고객",
    content:
      "무사고 차량으로 알았는데, 번호판 입력해 보니 정비 이력이 5건이나 되네요. 숨은이력 안 찾아봤으면 정말 큰일 날 뻔했어요.",
  },
  {
    iconSrc: accidentImg,
    title: "“알고보니\n렌터카 차량이었어요”",
    caption: "67나1457 조회 고객",
    content:
      "매매 단지에 직접 방문해 꼼꼼히 살펴본 뒤 구매 직전, 이력을 조회해봤어요. 그런데 알고보니 렌터카로 3년이나 이용했던 차량이더라구요.",
  },
  {
    iconSrc: sunImg,
    title: "“5년 전 이력까지\n확인하니 안심되네요!”",
    caption: "13가8215 조회 고객",
    content:
      "중고차 구매는 처음이라 막막했는데.. 5년 전 이력까지 확인할 수 있으니까 덕분에 안심하고 구매할 수 있었어요!",
  },
];
