/** @jsxImportSource @emotion/react */
import React from "react";
import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";

import ShowInView from "components/ShowInView";

import inspectionReportBg from "../assets/bg/inspection_report.png";
import carHistoryImg from "../assets/img/car_history.png";
import inspectionReportImg from "../assets/img/inspection_report.png";
import mockupScreenImg from "../assets/img/mockup_screen.gif";
import ImageSlide from "../components/ImageSlide";
import Mockup from "../components/Mockup";
import { reviews } from "../constants/reviews";

import maintenanceHistoryImg from "./assets/img/maintenance_history.png";
import ReviewItem from "./components/ReviewItem";
import { customTypographyStyles } from "./styles";

const titleWrapperStyle = css`
  display: grid;

  gap: 1rem;

  padding: 4.5rem 2rem 0;
`;

const reviewScrollWrapperStyle = css`
  display: grid;

  grid-auto-flow: column;

  gap: 0.75rem;

  padding: 2.5rem 0 2.5rem 2rem;
  overflow: auto;

  scroll-snap-type: x mandatory;
  scroll-padding-left: 2rem;

  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
`;

const MobileTotalInfoPage: FC = () => {
  return (
    <div>
      <div css={{ backgroundColor: "#000000f5", overflow: "hidden" }}>
        <ShowInView>
          <div css={[titleWrapperStyle, { color: colors.base_white }]}>
            <Typography variant="subtitle_1" color="brand_primary">
              중고차 숨은이력 찾기
            </Typography>
            <div css={customTypographyStyles.headline_2_fit}>
              구매할 중고차, <br />
              번호만 입력하세요
            </div>
          </div>
        </ShowInView>
        <ShowInView>
          <div css={{ padding: "5rem 2.5rem 0", display: "flex", justifyContent: "center" }}>
            <div css={{ width: "12.5rem", height: "20.625rem", overflow: "hidden" }}>
              <Mockup screenSrc={mockupScreenImg} />
            </div>
          </div>
        </ShowInView>
      </div>
      <div css={{ backgroundColor: colors.base_gray3, overflow: "hidden" }}>
        <ShowInView>
          <div css={[titleWrapperStyle, { color: colors.base_dark }]}>
            <div css={customTypographyStyles.headline_2_fit}>
              <div>어디에도 없던</div>
              <div css={{ color: colors.brand_primary }}>숨은 정비 이력</div>
            </div>
            <Typography variant="body_1" color="base_light">
              흩어져 있는 정비 이력을 한 곳에 모아놨어요.
            </Typography>
          </div>
        </ShowInView>
        <ShowInView>
          <div css={{ padding: "2.5rem 3.5rem", display: "flex", justifyContent: "center" }}>
            <img src={maintenanceHistoryImg} css={{ width: "15.5rem", height: "18.625rem", objectFit: "contain" }} />
          </div>
        </ShowInView>
      </div>
      <div css={{ backgroundColor: colors.base_gray2, overflow: "hidden" }}>
        <ShowInView>
          <div css={[titleWrapperStyle, { color: colors.base_dark }]}>
            <div css={customTypographyStyles.headline_2_fit}>
              <div css={{ color: colors.brand_primary }}>19가지 차량 이력</div>
              <div>투명하게 공개</div>
            </div>
            <Typography variant="body_1" color="base_primary">
              구매할 차량의 모든 이력을 확인해보세요.
            </Typography>
          </div>
        </ShowInView>
        <ShowInView>
          <div css={{ padding: "2.5rem 0", height: "17.5rem" }}>
            <ImageSlide src={carHistoryImg} />
          </div>
        </ShowInView>
      </div>
      <div
        css={{
          backgroundImage: `linear-gradient(#000000C2, #000000C2), url(${inspectionReportBg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <ShowInView>
          <div css={[titleWrapperStyle, { color: colors.base_white }]}>
            <div css={customTypographyStyles.headline_2_fit}>
              <div>전문가가 작성한</div>
              <div css={{ color: colors.brand_primary }}>하부 점검 리포트</div>
            </div>
            <Typography variant="body_1">숨겨진 누유, 부식, 파손 여부도 알 수 있어요.</Typography>
          </div>
        </ShowInView>
        <ShowInView>
          <div css={{ padding: "2.5rem 5rem 0", display: "flex", justifyContent: "center" }}>
            <div css={{ width: "12.5rem", height: "19.3125rem", overflow: "hidden" }}>
              <Mockup screenSrc={inspectionReportImg} />
            </div>
          </div>
        </ShowInView>
      </div>
      <div css={{ backgroundColor: colors.base_gray2, overflow: "hidden" }}>
        <ShowInView>
          <div css={[titleWrapperStyle, { color: colors.base_dark }]}>
            <div css={customTypographyStyles.headline_2_fit}>
              구매 전에 <br />꼭 확인하세요
            </div>
          </div>
        </ShowInView>
        <ShowInView>
          <div css={{ margin: "0 auto", display: "grid", justifyContent: "center" }}>
            <div css={reviewScrollWrapperStyle}>
              {reviews.map((review) => (
                <div key={review.title} css={{ width: "15rem", display: "grid" }}>
                  <ReviewItem {...review} />
                </div>
              ))}
              <div css={{ width: "1.25rem" }} />
            </div>
          </div>
        </ShowInView>
      </div>
    </div>
  );
};

export default MobileTotalInfoPage;
