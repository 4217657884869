/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import React from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";
import { Link } from "react-router-dom";

import {
  logClickHeaderMenuPRNDEvent,
  logClickHeaderMenuQNAEvent,
  logClickHeaderMenuScrapEvent,
  logClickHeaderMenuTotalInfoEvent,
} from "analytics";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { ReactComponent as NewBadge } from "assets/img/new_badge.svg";

import { prndLink, scrapLink } from "../../../config";

import MenuItem from "./MenuItem";

const headerStyle = css`
  position: sticky;
  top: 0;
  z-index: 1;

  width: 100%;

  background-color: ${colors.base_white}E6;
`;

const headerLayoutStyle = css`
  box-sizing: border-box;
  display: flex;

  align-items: center;
  justify-content: space-between;

  max-width: 58.625rem;
  padding: 1rem 0 0.5rem;
  margin: 0 auto;
`;

const ulStyle = css`
  all: initial;

  display: grid;

  grid-auto-flow: column;

  gap: 3rem;

  list-style: none;
`;

const DesktopHeader: FC = () => {
  return (
    <header css={headerStyle}>
      <div css={headerLayoutStyle}>
        <Link to="/" css={{ cursor: "pointer" }}>
          <Logo css={{ display: "block", width: "auto", height: "3rem" }} />
        </Link>

        <nav>
          <ul css={ulStyle}>
            <li>
              <MenuItem onClick={() => logClickHeaderMenuTotalInfoEvent()} to="/total-info">
                <div css={{ display: "grid", gridTemplateColumns: "1fr 1rem", gap: "0.25rem", alignItems: "start" }}>
                  <Typography variant="body_1">중고차 숨은이력 찾기</Typography>
                  <NewBadge css={{ width: "1rem", height: "1rem" }} />
                </div>
              </MenuItem>
            </li>
            <li>
              <MenuItem onClick={() => logClickHeaderMenuScrapEvent()} href={scrapLink} target="_blank" external>
                <Typography variant="body_1">폐차 비교견적</Typography>
              </MenuItem>
            </li>
            <li>
              <MenuItem onClick={() => logClickHeaderMenuQNAEvent()} to={"/qna"}>
                <Typography variant="body_1">자주묻는 질문</Typography>
              </MenuItem>
            </li>
            <li>
              <MenuItem onClick={() => logClickHeaderMenuPRNDEvent()} href={prndLink} target="_blank" external>
                <Typography variant="body_1">PRND 채용</Typography>
              </MenuItem>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default DesktopHeader;
