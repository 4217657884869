import { eventAnalytics } from "modules/eventAnalytics";

type LogClickFooterMenuEventParams = {
  type: string;
};

export const logClickFooterMenuEvent = (params: LogClickFooterMenuEventParams) => {
  eventAnalytics.ga?.event("click_footer_menu", params);
};

export const logClickFooterMenuAppDownloadEvent = () => {
  logClickFooterMenuEvent({ type: "app_download" });
  eventAnalytics.ga?.event("click_footer_menu_app_download");
};

export const logClickFooterMenuQNAEvent = () => {
  logClickFooterMenuEvent({ type: "qna" });
  eventAnalytics.ga?.event("click_footer_menu_qna");
};

export const logClickFooterMenuPRNDEvent = () => {
  logClickFooterMenuEvent({ type: "prnd" });
  eventAnalytics.ga?.event("click_footer_menu_prnd");
};

export const logClickFooterMenuTermsOfServiceEvent = () => {
  logClickFooterMenuEvent({ type: "terms_of_service" });
  eventAnalytics.ga?.event("click_footer_menu_terms_of_service");
};

export const logClickFooterMenuPrivacyPolicyEvent = () => {
  logClickFooterMenuEvent({ type: "privacy_policy" });
  eventAnalytics.ga?.event("click_footer_menu_privacy_policy");
};
