import { createElement, type ButtonHTMLAttributes, type DetailedHTMLProps, type ReactNode } from "react";

import classNames from "classnames";
import { SyncLoader } from "react-spinners";

import { base_white } from "../color-palette/color-palette";

import styles from "./Button.module.scss";

export type buttonVariant = "primaryBrandcolor" | "secondaryBrandcolor" | "secondaryGray" | "textBrandcolor";
export type buttonSize = "big" | "medium" | "small" | "tiny";

export interface Props {
  // style
  variant?: buttonVariant;
  size?: buttonSize;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  text?: string;
  className?: string;
  isLoading?: boolean;
  href?: string;
}

const Button: React.FC<Props & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
  variant = "primaryBrandcolor",
  size = "big",
  leftIcon = null,
  rightIcon = null,
  text,
  className,
  isLoading = false,
  href,
  ...otherProps
}) => {
  const buttonClassName = classNames(
    styles.button,
    styles[variant],
    styles[size],
    otherProps.disabled && styles.disabled,
    className
  );

  const renderChildren = () => {
    return isLoading ? (
      <>
        <SyncLoader color={base_white} loading size={6} />
      </>
    ) : (
      <>
        {leftIcon && (
          <>
            {leftIcon}
            <span className={styles.marginRight} />
          </>
        )}
        {text && text}
        {rightIcon && (
          <>
            <span className={styles.marginLeft} />
            {rightIcon}
          </>
        )}
      </>
    );
  };

  const tag = createElement(
    href ? "a" : "button",
    {
      className: classNames(buttonClassName),
      href: href ? href : null,
      target: "_blank",
      ...otherProps,
    },
    renderChildren()
  );

  return <>{tag}</>;
};

export default Button;
