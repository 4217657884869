import type { Dispatch, SetStateAction } from "react";
import { useLayoutEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import useIsMobile from "./useIsMobile";

type DefaultParams = { defaultTemplateId: string; defaultURLId: string };
type UseAppLinkReturnType = {
  url: URL;
  storeParams: (defaultParams: DefaultParams) => void;
};

const MKT_APPSFLYER_TEMPLATE_ID_KEY = "mkt_af_tpl_id";

const MKT_APPSFLYER_URL_ID_KEY = "mkt_af_id";
const MKT_APPSFLYER_URL_ID_PC_KEY = "mkt_af_id_pc";
const MKT_APPSFLYER_URL_ID_MOBILE_KEY = "mkt_af_id_mobile";

const EXCLUDE_PARAM_KEYS = [
  MKT_APPSFLYER_TEMPLATE_ID_KEY,
  MKT_APPSFLYER_URL_ID_KEY,
  MKT_APPSFLYER_URL_ID_PC_KEY,
  MKT_APPSFLYER_URL_ID_MOBILE_KEY,
];

const handlers: Dispatch<SetStateAction<URLSearchParams>>[] = [];

const getURLId = (isMobile: boolean, searchParams: URLSearchParams) => {
  const urlId = searchParams.get(MKT_APPSFLYER_URL_ID_KEY);
  const urlIdPC = searchParams.get(MKT_APPSFLYER_URL_ID_PC_KEY);
  const urlIdMobile = searchParams.get(MKT_APPSFLYER_URL_ID_MOBILE_KEY);

  return urlId ?? (isMobile ? urlIdMobile : urlIdPC);
};

const useApplink = (projectKey: string): UseAppLinkReturnType => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(new URLSearchParams(sessionStorage.getItem(projectKey) ?? ""));
  const isMobile = useIsMobile();

  const storeParams = ({ defaultTemplateId, defaultURLId }: DefaultParams) => {
    const sessionStoredParams = new URLSearchParams(sessionStorage.getItem(projectKey) ?? "");

    sessionStoredParams.set(
      MKT_APPSFLYER_TEMPLATE_ID_KEY,
      searchParams.get(MKT_APPSFLYER_TEMPLATE_ID_KEY) ??
        sessionStoredParams.get(MKT_APPSFLYER_TEMPLATE_ID_KEY) ??
        defaultTemplateId
    );

    sessionStoredParams.set(
      MKT_APPSFLYER_URL_ID_KEY,
      getURLId(isMobile, searchParams) ?? getURLId(isMobile, sessionStoredParams) ?? defaultURLId
    );

    const gclid = sessionStoredParams.get("gclid");
    if (gclid) {
      sessionStoredParams.set("af_sub1", gclid);
    }

    sessionStorage.setItem(projectKey, sessionStoredParams.toString());
    handlers.map((setParams) => setParams(sessionStoredParams));
  };

  const templateId = params.get(MKT_APPSFLYER_TEMPLATE_ID_KEY);
  const urlId = params.get(MKT_APPSFLYER_URL_ID_KEY);

  const url = new URL(`${templateId}/${urlId}`, "https://applink.heydealer.com");
  url.search = new URLSearchParams(
    [...searchParams.entries()].filter(([key]) => !EXCLUDE_PARAM_KEYS.includes(key))
  ).toString();

  useLayoutEffect(() => {
    handlers.push(setParams);

    return () => {
      handlers.splice(handlers.indexOf(setParams), 1);
    };
  }, []);

  return { url, storeParams };
};

export default useApplink;
