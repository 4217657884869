/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import type { FC } from "react";

import Layout from "components/Layout";
import { MKT_PROJECT_KEY } from "constants/index";
import useApplink from "hooks/useApplink";
import useIsMobile from "hooks/useIsMobile";

import DesktopTotalInfoPage from "./desktop";
import DesktopStickyBottom from "./desktop/StickyBottom";
import MobileTotalInfoPage from "./mobile";
import MobileStickyBottom from "./mobile/StickyBottom";

const TotalInfoPage: FC = () => {
  const isMobile = useIsMobile();

  const { storeParams } = useApplink(MKT_PROJECT_KEY);

  useEffect(() => {
    storeParams({ defaultTemplateId: "QbFw", defaultURLId: isMobile ? "HomepageMobileHistory" : "HomepagePCHistory" });
  }, []);

  return (
    <Layout stickyBottom={isMobile ? <MobileStickyBottom /> : <DesktopStickyBottom />}>
      {isMobile ? <MobileTotalInfoPage /> : <DesktopTotalInfoPage />}
    </Layout>
  );
};

export default TotalInfoPage;
